import * as React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProtectedRoute from "../components/ProtectedRoute"

const Verkauf = () => (
  <Layout>
    <Seo title="Verkauf" />
    <Header />
    <ProtectedRoute />
  </Layout>
)

export default Verkauf
