// src/components/ProtectedRoute.js
import React, { useState } from 'react';
import { navigate } from 'gatsby';

const ProtectedRoute = () => {
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if(password === 'mypassword') {
      // If the password is correct, navigate to the protected page
      navigate('/verkauf-formular');
    } else {
      alert('Wrong password');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Password:
        <input type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
      </label>
      <input type="submit" value="Submit" />
    </form>
  );
};

export default ProtectedRoute;
